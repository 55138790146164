.NewsWrapper {
    padding: 0px 16px !important;
    margin-bottom: 32px;
}

.newsSwiperContainer {
    height: 520px;
}

.newsSwiperSlideContainer {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 16px;
    border-radius: 16px;
    height: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.newsSwiperSlideHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(35, 78, 136, 0.8);
}

.newsSwiperSlideHeader h2,
.newsSwiperSlideHeader h3 {
    color: var(--primary);
    font-weight: bold;
}

.newsSwiperSlideContent {
    margin-top: 8px;
}

.newsSwiperSlideContentInfos {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newsSwiperSlideContentInfos p {
    text-transform: capitalize;
}

.newsSwiperSlideContentImage {
    width: 100%;
    height: 20vh;
}

.newsSwiperSlideContentImage img {
    width: 100%;
    height: 100%;
}

.newsSwiperSlideContentTitleDesc {
    margin-top: 8px;
}

.newsSwiperSlideContentTitleDesc h6 {
    color: var(--primary);
    font-weight: bold;
}

.newsSwiperSlideContentTitleDesc p {
    line-height: 20px;
    margin: 0;
    height: 110px;
}

.newsSwiperSlideFooter {
    width: 100%;
    margin-top: 8px;
}

.newsSwiperSlideFooterButton {
    width: 100%;
    background-color: var(--secondary) !important;
    border: none !important;
    border-radius: 8px !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary) !important;
    color: var(--white) !important;
    padding: 8px !important;
    border-radius: 100% !important;
    font-size: 18px !important;
}