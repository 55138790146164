.offCanvas {
    height: 100vh;
    background-color: var(--white);
    overflow-x: hidden !important;
}

.offcanvas-body {
    padding: 0px 0px 0px 0px !important;
    overflow-x: hidden !important;
}

.usernameTitle,
.passwordTitle {
    margin-bottom: 0px;
    color: var(--gray);
    font-size: 16px;
}

.date {
    height: 45px;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding-left: 8px;
    cursor: pointer;
    border-radius: 100px;
}

.canvasTitle {
    font-weight: bold;
    color: var(--primary);
}

.phoneNumber {
    height: 45px !important;
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 100px;
}

.PhoneInputInput {
    border: none;
    color: #000000;
}

.textInput {
    margin-bottom: 24px;
    border-radius: 100px;
    height: 45px;
}


.signupinputLabel {
    font-size: 16px;
}

.ModalFooterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.ModalFooterSubmit,
.ModalFooterSubmit:focus {
    width: 100%;
    background-color: var(--secondary);
    border: none;
    padding: 8px;
    border-radius: 100px;
    font-weight: bold;
    color: var(--white);
}

.ModalFooterSubmit:hover {
    background-color: var(--third);
}

.signupFormsContainer {
    display: flex;
    margin-bottom: 24px;
    margin-top: 16px;
}

.warningBody {
    padding: 16px;
}

.warningButton,
.warningButton:focus,
.warningButton:hover {
    background-color: var(--primary);
    color: var(--white);
    font-weight: bold;
    border: none;
    padding: 8px;
    width: 100% !important;
    border-radius: 0px !important;
}

.SwitchContainer {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border: none;
    padding: 8px;
    border-radius: 20px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SwitchText {
    font-size: 25px;
    text-align: center;
    padding-top: 17px;
    padding-bottom: 17px;
}

.SwitchText2 {
    font-size: 18px;
    text-align: center;
    padding-bottom: 27px;
}

.SwitchText3 {
    font-size: 25px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 70px;
}

.SwitchText4 {
    font-size: 20px;
    text-align: center;
    padding-bottom: 70px;
}

.rowCont {
    margin-top: 30px;
}

@media (max-width: 767.5px) {
    .SwitchContainer {
        margin-top: 10px;
    }

    .conta {
        margin-top: 10px;
    }

    .rowCont {
        margin-top: 300px;
    }
}

@media (max-width: 400px) {

    .rowCont {
        margin-top: 400px;
    }
}

.btn-close {
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}