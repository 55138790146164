.reviews-container {
    padding: 20px;
    background-color: var(--primary) !important;
}

.reviews-container h2 {
    color: var(--white);
    font-weight: bold !important;
}

.review-row {
    display: flex;
    align-items: center;
    overflow-x: scroll;
}

.review-card {
    margin: 8px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
    transition: 0.3s;
}

.review-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body {
    padding: 15px;
}

.card-title {
    margin-bottom: 0px;
    margin-left: 8px;
    color: var(--white);
    font-weight: bold;
}

.card-text {
    color: var(--white);
}

.text-muted {
    font-size: 0.8rem;
}

.card-text span {
    color: gold;
    font-size: 1.5rem;
}

.review-row::-webkit-scrollbar {
    /* width: 20px; */
    height: 5px;
}

/* Track */
.review-row::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.review-row::-webkit-scrollbar-thumb {
    background: var(--white);
    border-radius: 10px;
}

/* Handle on hover */
.review-row::-webkit-scrollbar-thumb:hover {
    background: var(--white);
}