.usaLeaguesWrapper {
    margin-top: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
}

.usaLeagueContainer {
    width: 150px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 16px;
}

.usaLeagueImgContainer {
    padding: 16px !important;
    border-bottom: 1px solid rgba(35, 78, 136, 1);
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usaLeagueContentContainer {
    padding: 8px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}

.usaLeagueText {
    font-weight: bold;
    color: var(--primary);
    padding: 0px;
    margin: 0px;
}