.historiesContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.historyTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:992px) {
    .historyImageContainer {
        display: none;
    }

    .historyHeader {
        height: 20vh;
    }
}

.modalHeader {
    background: linear-gradient(45deg, var(--primary), var(--third));
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}

.modalHeader h2 {
    color: var(--white) !important;
    font-weight: bold;
}