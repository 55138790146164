.NbaDraftsText h4 {
    color: var(--secondary);
    font-weight: bold !important;
}

.NbaDraftsContentContainer {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.NbaDraftsContent {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 8px;
    border-radius: 8px;
    width: 85px;
}

.NbaDraftsPickNoContainer h6 {
    color: var(--primary);
    font-weight: bold;
}

/* second Round */

.NbaDraftsContentSecondRound {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 8px;
    border-radius: 8px;
    width: 250px;
    display: flex;
    align-items: center;
}

.NbaDraftsContentSecondRoundNumContainer {
    border-right: 1px solid var(--primary);
    height: 100% !important;
    display: flex;
    align-items: center;
}

.NbaDraftsContentSecondRound .NbaDraftsContentSecondRoundNumContainer h6 {
    color: var(--primary);
    font-weight: bold;
    padding-right: 8px;
}