.GamesText h4 {
    color: var(--secondary);
    font-weight: bold !important;
}

.GamesHeaderContainer {
    border: 1px solid var(--primary);
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
}

.GamesHeaderCard {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    border-radius: 100px;
    cursor: pointer;
}

.GamesHeaderCardSelected {
    border-radius: 100px;
    padding: 8px 32px;
    display: flex;
    align-items: center;
    background-color: var(--secondary);
    color: var(--white);
    cursor: pointer;
}

.GamesContentContainer {
    margin: 32px 0px;
}

.GamesContentCardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.GamesContent h3 {
    margin: 16px 0px;
    font-weight: bold;
    color: var(--primary);
}

.GamesContentCard {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 16px;
    padding: 16px;
}

.GamesContentCardTeamsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.GamesContentCardHomeContainer,
.GamesContentCardAwayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.GamesContentCardScore {
    color: var(--white);
    font-weight: bold;
    background-color: rgba(35, 78, 136, 0.8);
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GamesContentCardStartTime {
    margin: 16px 0px;
    text-align: center;
    background-color: rgba(35, 78, 136, 0.8);
    border-radius: 100px;
    color: var(--white);
}

.GamesContentCardTicketLink a {
    text-align: center;
    color: var(--black);
}

.noGamesMessage {
    color: var(--primary);
    font-weight: bold;
}