/* .nbaRankingWrapper {} */

.nbaRankingText h4 {
    color: var(--secondary);
    font-weight: bold !important;
}

.nbaRankingContainer {
    margin-bottom: 16px;
    margin-top: 16px;
}

.nbaRankingContainer h5 {
    color: var(--black);
    font-weight: bold;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 8px;
}

.nbaRankingGamesContainer {
    display: flex;
    flex-direction: column;
}

.nbaRankingGames {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.nbaRankingGamesRank {
    background-color: rgba(35, 78, 136, 0.5) !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px
}

.nbaRankingGamesRank p {
    margin: 0;
    padding: 0;
    color: var(--primary);
    font-weight: bold;
}

.nbaRankingGamesImageContainer {
    margin-left: 8px;
}

.nbaRankingGamesNameContainer {
    margin-left: 8px;
}

.nbaRankingGamesNameContainer p {
    margin: 0;
    font-size: 14px;
}

.nbaRankingGamesWinLossContainer {
    margin-left: 8px;
}

.nbaRankingGamesWinLossContainer p {
    margin: 0;
    font-size: 14px;
}

.nbaRankingGamesWinLossContainer p b {
    color: var(--primary);
    font-weight: bold;
}

.nbaRankingGamesLast10Container {
    margin-left: 8px;
}

.nbaRankingGamesLast10Container p {
    margin: 0;
    font-size: 14px;
}