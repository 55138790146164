.highlightsWrapper {
    padding: 16px 32px;
    background-color: var(--primary);
}

.highlightsTitleContainer h2 {
    color: var(--white);
    font-weight: bold;
}

.highlightsContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
}

.highlightsContent {
    background-color: var(--white);
    border-radius: 16px;
    padding: 8px;
}